<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
  import {useStore} from "vuex";
  import ColorGuide from "@/components/ColorGuideTrafficLight";
  import ColorGuideDesc from "@/components/ColorGuideTrafficLightDesc";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ["country"],
  setup(){
    const store = useStore();
    const currentYear =  process.env.VUE_APP_YEAR || 2020;
    const indices1  = store.state.indexGuide.filter(d => d.axis == 1);
    const indices2  = store.state.indexGuide.filter(d => d.axis == 2);
    const indices3  = store.state.indexGuide.filter(d => d.axis == 3);
    const direction = index => index.direction.indexOf("up") != -1 ? '&#x2191;' : '&#x2193;';

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      indices1,
      indices2,
      indices3,
      getData : store.getters.getValueFromIndexWithCountryAndYear,
      format  : store.getters.formatNumber,
      color   : store.getters.getColor,
      year    : process.env.VUE_APP_YEAR || 2020,
      direction,
      currentYear
    }

  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components : {
      ColorGuide,
      ColorGuideDesc
    }
}
</script>
<template>
<div>
    <section>
        <h1 class="mb-4 pb-4">Estatus en <strong>{{country.name}}</strong></h1>
        <p class="pb-4 mb-4">A continuación se muestra el progreso de <strong>{{country.name}}</strong> hacia los objetivos de la estrategia 90-90-90 y la situación actual en los indicadores para medir la eficiencia y pertinencia del gasto en VIH. 
En los indicadores que deben progresar de manera ascendente (se busca llegar al máximo posible, como en los objetivos 90-90-90), va de rojo -en donde el porcentaje es igual o menor a 25- a azul -en donde el porcentaje es igual o mayor a 90-. 
Al contrario, en los indicadores que deben progresar de manera descendente (se busca llegar al mínimo posible, como en la transmisión materno-infantil), va de azul -en donde el porcentaje es igual o mayor a 90- a rojo -en donde el porcentaje es igual o menor a 25-. 
En ambos casos el color gris quiere decir que la información no está disponible. 
</p>
        <!-- 
        <h2 class="mt-3">Cumplimiento de objetivos 2020 en <strong>{{country.name}}</strong></h2>
        
        objetivo 
        <div class="row mt-4 pt-4">
            <div class="col-12">
                <h3 class="mb-1">Objetivo 1. <strong>Progreso 90 - 90 - 90</strong></h3>
                <p>90% de las personas (niños, niñas, adolescentes y adultos) que viven con el VIH conocen su estado <br>
                90% de las personas que viven con el VIH y que conocen su estado están recibiendo tratamiento<br>
                90% de las personas en tratamiento han suprimido la carga viral</p>
                <h4>Compromiso 1</h4>
                <p>Garantizar que los 30 millones de personas que viven con el VIH tienen acceso al tratamiento mediante el cumplimiento de los objetivos 90-90-90 antes de 2020</p>
            </div>
        </div>
        1-->

        

        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <h3>Eje 1: Eficiencia del gasto en VIH</h3>
            <div class="row mt-4">
              <div class="col-sm-6">
                <h5>Metas Ascendentes &#x2191; </h5>
                <ColorGuide/>
              </div>
              <div class="col-sm-6">
                <h5>Metas Descendentes &#x2193; </h5>
                <ColorGuideDesc/>
              </div>
            </div>
          </div>
        </div>


        <div class="row mt-4 pt-4">
            <div class="col-sm-10 offset-sm-1">

              <!-- títulos -->
              <div class="row">
                <div class="col-5">
                  <p class="mb-0 im_label">Indicador</p>
                </div>
                <div class="col-2">
                  <p class="mb-0 text-right im_label">Sentido</p>
                </div>
                <div class="col-4">
                  <p class="mb-0 im_label">Estatus</p>
                </div>
                <div class="ip_separator bottom"></div>
              </div>
              
              <!-- indices eje 1 -->
              <div class="row" v-for="index of indices1" :key="`table-${index.db_name}`">
                <div class="col-6">
                  <p class="mb-0">{{index.name}}</p>
                </div>
                <div class="col-1">
                  <p class="mb-0 im_label direction text center" v-html="direction(index)"></p>
                </div>
                <div class="col-5" v-if="getData(index.db_name, country.id, year) == null">
                  <div :class="`od_colorguide _bg`">
                    <div class="od_triangle_danger"></div>
                  </div>
                </div>
                <div class="col-5" v-else>
                  <div :class="`${color(index.db_name, getData(index.db_name, country.id, year))} _bg`">
                    {{format(getData(index.db_name, country.id, year))}}%
                  </div>
                </div>
                
                <div class="ip_separator bottom"></div>
              </div>
              <!--source-->
              <div class="row">
                <div class="col-sm-6 offset-sm-6">
                  <p class="od_source text-right">Información correspondiente a {{currentYear}}. Fuentes: Onusida, Banco Mundial, Informes GAM, portales de contrataciones de los gobiernos.</p>
                </div>
              </div>
            </div>
          </div>

          
          <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <h3 class="mt-4 pt-4">Eje 2: Pertinencia de la inversión</h3>
            <div class="row mt-4">
              <div class="col-sm-6">
                <h5>Metas Ascendentes &#x2191; </h5>
                <ColorGuide/>
              </div>
              <div class="col-sm-6">
                <h5>Metas Descendentes &#x2193; </h5>
                <ColorGuideDesc/>
              </div>
            </div>
          </div>
        </div>
          
          <div class="row mt-4 pt-4">
            <div class="col-sm-10 offset-sm-1">
              <!-- títulos -->
              <div class="row">
                <div class="col-5">
                  <p class="mb-0 im_label">Indicador</p>
                </div>
                <div class="col-2">
                  <p class="mb-0 text-right im_label">Sentido</p>
                </div>
                <div class="col-4">
                  <p class="mb-0 im_label">Estatus</p>
                </div>
                <div class="ip_separator bottom"></div>
              </div>

              <!-- indices eje 2 -->
              <div class="row" v-for="index of indices2" :key="`table-${index.db_name}`">
                <div class="col-6">
                  <p class="mb-0">{{index.name}}</p>
                </div>
                <div class="col-1">
                  <p class="mb-0 im_label direction text center" v-html="direction(index)"></p>
                </div>
                <div class="col-5" v-if="getData(index.db_name, country.id, year) == null">
                  <div :class="`od_colorguide _bg`">
                    <div class="od_triangle_danger"></div>
                  </div>
                </div>
                <div class="col-5" v-else>
                  <div :class="`${color(index.db_name, getData(index.db_name, country.id, year))} _bg`">
                    {{format(getData(index.db_name, country.id, year))}}%
                  </div>
                </div>
                
                <div class="ip_separator bottom"></div>
              </div>
              <!--source-->
              <div class="row">
                <div class="col-sm-6 offset-sm-6">
                  <p class="od_source text-right">Información correspondiente a {{currentYear}}. Fuentes: Onusida, Banco Mundial, Informes GAM, portales de contrataciones de los gobiernos.</p>
                </div>
              </div>
            </div>
          </div>
          

          <div class="row">
          <div class="col-sm-10 offset-sm-1">
              <h3 class="mt-4 pt-4">Eje 3: Servicios</h3>
            <div class="row mt-4">
              <div class="col-sm-6">
                <h5>Metas Ascendentes &#x2191; </h5>
                <ColorGuide/>
              </div>
              <div class="col-sm-6">
                <h5>Metas Descendentes &#x2193; </h5>
                <ColorGuideDesc/>
              </div>
            </div>
          </div>
        </div>
          
          <div class="row mt-4 pt-4">
            <div class="col-sm-10 offset-sm-1">
              <!-- títulos -->
              <div class="row">
                <div class="col-5">
                  <p class="mb-0 im_label">Indicador</p>
                </div>
                <div class="col-2">
                  <p class="mb-0 text-right im_label">Sentido</p>
                </div>
                <div class="col-4">
                  <p class="mb-0 im_label">Estatus</p>
                </div>
                <div class="ip_separator bottom"></div>
              </div>
              
              <!-- indices eje 3 -->
              <div class="row" v-for="index of indices3" :key="`table-${index.db_name}`">
                <div class="col-6">
                  <p class="mb-0">{{index.name}}</p>
                </div>
                <div class="col-1">
                  <p class="mb-0 im_label direction text center" v-html="direction(index)"></p>
                </div>
                <div class="col-5" v-if="getData(index.db_name, country.id, year) == null">
                  <div :class="`od_colorguide _bg`">
                    <div class="od_triangle_danger"></div>
                  </div>
                </div>
                <div class="col-5" v-else>
                  <div :class="`${color(index.db_name, getData(index.db_name, country.id, year))} _bg`">
                    {{format(getData(index.db_name, country.id, year))}}%
                  </div>
                </div>
                
                <div class="ip_separator bottom"></div>
              </div>
              <!-- primer 90-->
              <!-- <div class="row">
                <div class="col-6">
                  <p class="mb-0">Porcentaje de personas que viven con el VIH que conocen su estado serológico</p>
                </div>
                <div class="col-5">
                  <div class="od_colorguide _bg"><div class="od_triangle_danger"></div></div>
                </div>
                <div class="col-1">
                  <p class="mb-0 im_label">&#x2191;</p>
                </div>
              <div class="ip_separator bottom"></div>
              </div> -->

              <!-- segundo 90-->
              <!-- <div class="row">
                <div class="col-6">
                 <p class="mb-0">Porcentaje de personas que viven con VIH que reciben TARV</p>
                </div>
                <div class="col-5">
                  <div class="od_colorguide _75 _bg">60%</div>
                </div>
                <div class="col-1">
                  <p class="mb-0 im_label">&#x2191;</p>
                </div>
               <div class="ip_separator bottom"></div>
              </div> -->

              <!-- tercer 90-->
              <!-- <div class="row">
                <div class="col-6">
                  <p class="mb-0">Porcentaje de personas que viven con el VIH que reciben tratamiento que han suprimido su carga viral</p>
                </div>
                <div class="col-5">
                  <div class="od_colorguide _50 _bg">46%</div>
                </div>
                <div class="col-1">
                  <p class="mb-0 im_label">&#x2191;</p>
                </div>
                 <div class="ip_separator bottom"></div>
              </div>  

              <div class="row">
                <div class="col-6">
                  <p class="mb-0">Transmisión materno infantil del VIH</p>
                </div>
                <div class="col-5">
                  <div class="od_colorguide _des50 _bg">46%</div>
                </div>
                <div class="col-1">
                  <p class="mb-0 im_label">&#x2193;</p>
                </div>
                 <div class="ip_separator bottom"></div>
              </div>   -->

              

              <!--source-->
              <div class="row">
                <div class="col-sm-6 offset-sm-6">
                  <p class="od_source text-right">Información correspondiente a {{currentYear}}. Fuentes: Onusida, Banco Mundial, Informes GAM, portales de contrataciones de los gobiernos.</p>
                </div>
              </div>
            </div>
        </div>

       




    </section>
</div>
</template>